import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import styled, { css } from 'styled-components';

export const DropdownTrigger = styled(DropdownMenu.Trigger)`
  && {
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
  }
`;

export const Container = styled.div<{ category: string }>`
  ${({ theme, category }) => css`
    display: flex;
    align-items: center;
    padding: ${theme.spacing['10']} $;
    gap: ${theme.spacing['08']};
    z-index: 1;
    ${category === 'account' &&
    css`
      padding: ${theme.spacing['08']} ${theme.spacing['16']}
        ${theme.spacing['20']} ${theme.spacing['16']};
      justify-content: center;
    `}

    ${category !== 'account' &&
    css`
      border: none;
    `}
  `}
`;

export const Text = styled.div<{ category: string }>`
  ${({ theme, category }) => css`
    color: ${theme.colors.gray['900']};
    font-family: ${theme.typography.fontFamily.inter};
    font-size: ${theme.typography.fontSize['14'].size};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.medium};
    line-height: ${theme.spacing['20']};

    ${category === 'danger' &&
    css`
      color: ${theme.colors.error['600']};
    `}
    ${category === 'multi-line' &&
    css`
      color: ${theme.colors.gray['900']};
    `}
    ${category === 'account' &&
    css`
      color: ${theme.colors.gray['900']};
      font-weight: ${theme.typography.fontWeight.bold};
    `}
    ${category === 'category' &&
    css`
      color: ${theme.colors.gray['900']};
      font-size: ${theme.typography.fontSize['12'].size};
      font-weight: ${theme.typography.fontWeight.bold};
      line-height: 16px;
    `}
  `}
`;

export const SubText = styled.div<{ category: string }>`
  ${({ theme, category }) => css`
    color: ${theme.colors.gray['500']};
    font-family: ${theme.typography.fontFamily.inter};
    font-size: ${theme.typography.fontSize['12'].size};
    font-style: normal;
    font-weight: ${theme.typography.fontWeight.regular};
    line-height: 16px;

    ${category === 'account' &&
    css`
      color: ${theme.colors.gray['700']};
    `}
  `}
`;

export const ListItem = styled(DropdownMenu.Item)`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: start;
    width: 100%;
    border: none;
    gap: ${theme.spacing['08']};
    padding: ${theme.spacing['08']} ${theme.spacing['16']};
    font-size: ${theme.typography.fontSize['14'].size};
    font-weight: ${theme.typography.fontWeight.regular};
    border-radius: ${theme.radius.sm};
    cursor: pointer;
    color: ${theme.colors.gray['900']};
    svg {
      display: flex;
      height: 20px;
      width: 20px;
      justify-content: center;
      align-items: center;
    }
    &[data-highlighted] {
      outline: none;
      border: none;
      box-shadow: none;
    }

    &:hover {
      background-color: #e4e4e7;
    }

    /* Estilo do último item */
    &.customLastItem {
      color: ${theme.colors.error['600']};
      font-weight: ${theme.typography.fontWeight.bold};

      &:hover {
        background-color: ${theme.colors.error['50']};
      }
    }
  `}
`;

export const DropdownContent = styled(DropdownMenu.Content)`
  ${({ theme }) => css`
    border-radius: ${theme.radius.md};
    border: 1px solid var(--Colors-Border-Secondary-border-secondary, #e4e4e7);
    background: ${theme.colors.white};
    box-shadow: ${theme.shadows.xl};
    width: 250px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: ${theme.spacing['08']};
    gap: ${theme.spacing['08']};
    z-index: 999;
    overflow-y: auto;
  `}
`;

export const DividerLine = styled.hr`
  ${({ theme }) => css`
    width: 100%;
    height: 1px;
    background-color: ${theme.newColors.borderSecondaryBorderSecondary};
    border: none;
  `}
`;

export const IconWrapper = styled.div<{
  isOpen: boolean;
}>`
  display: flex;
  align-items: center;
  border-radius: 6px;
  background-color: white;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid var(--Colors-Border-Primary-border-primary, #d4d4d8);

  ${({ isOpen }) =>
    isOpen &&
    css`
      box-shadow: 4px 4px 0px 3px rgba(105, 56, 239, 0.6);

      box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 1);
    `}
`;

export const AccountImage = styled.img<{ src: string }>`
  width: 40px;
  height: 40px;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
  object-fit: cover;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`;
