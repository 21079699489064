'use client';
import { ArrowOutward } from '@mui/icons-material';

import { LinkButton } from '../link-button';
import * as S from './styles';

interface TipsProps {
  type: 'info' | 'warning' | 'error' | 'success' | 'tip' | 'error-warning';
  message: string | React.ReactNode;
  link?: string;
}

const Tips = ({ type = 'success', message, link = '' }: TipsProps) => {
  let label = '';
  switch (type) {
    case 'tip':
      label = 'Dica';
      break;
    case 'info':
      label = 'Informação';
      break;
    case 'warning':
    case 'error-warning':
      label = 'Atenção';
      break;
    case 'error':
      label = 'Erro';
      break;
    default:
      label = 'Sucesso';
      break;
  }

  return (
    <S.Tips type={type}>
      <span></span>
      <label>
        <span>{label}: </span>
        <div>
          <label>{message}</label>
          &nbsp;
          {!!link.length && (
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: 'underline', cursor: 'pointer' }}>
              clique aqui
            </a>
          )}
        </div>
      </label>
    </S.Tips>
  );
};

export default Tips;
