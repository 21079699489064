import {
  Content,
  Item,
  ItemText,
  Root,
  Trigger,
  Viewport,
} from '@radix-ui/react-select';
import styled, { css } from 'styled-components';

export const Label = styled.label`
  ${({ theme }) => css`
    color: ${theme.colors.gray['900']};
    font-size: ${theme.typography.fontSize['14'].size};
    font-weight: ${theme.typography.fontWeight.medium};
    line-height: ${theme.typography.fontSize['14'].lineHeight};
    margin-bottom: ${theme.spacing['06']};
    display: inline-block;
  `}
`;

export const SelectRoot = styled(Root)`
  width: 100%;
`;

export const SelectTrigger = styled(Trigger)`
  ${({ theme, defaultValue = '' }) => css`
    && {
      width: 100%;
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      border-radius: ${theme.spacing['08']};
      padding: ${theme.spacing['08']} ${theme.spacing['16']};
      background-color: ${theme.colors.white};
      border: 1px solid ${theme.colors.gray['200']};
      box-sizing: border-box;
      gap: ${theme.spacing[10]};

      font-size: ${theme.typography.fontSize['14'].size};
      font-weight: ${theme.typography.fontWeight.regular};
      line-height: ${theme.typography.fontSize['14'].lineHeight};

      color: ${!defaultValue
        ? theme.colors.gray['400']
        : theme.colors.gray['800']};

      &:active {
        color: ${theme.colors.gray['900']};
      }

      &:hover {
        border: 1px solid ${theme.colors.gray['700']};
        color: ${theme.colors.gray['900']};
      }

      &:focus-visible {
        outline: unset;
      }

      &[data-disabled] {
        border: 1px solid ${theme.colors.gray['200']};
        background: ${theme.colors.gray['100']};

        cursor: not-allowed;
        color: ${theme.colors.gray['300']};
      }

      & > .selectIcon {
        display: flex;
      }

      & > span:first-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  `}
`;

export const SelectContent = styled(Content)`
  z-index: 2;
  width: var(--radix-select-trigger-width);
`;

export const ViewPort = styled(Viewport)`
  ${({ theme }) => css`
    && {
      border-radius: ${theme.radius['xs']};
      border: 1px solid ${theme.colors.gray['200']};
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing['04']};
      max-height: 300px;
      overflow-y: scroll;
      scrollbar-width: thin;
      scrollbar-color: ${theme.colors.gray['400']} ${theme.colors.gray['200']};
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      &::-webkit-scrollbar-track {
        background: ${theme.colors.gray['400']};
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${theme.colors.gray['300']};
        border-radius: 4px;
        border: 1px solid ${theme.colors.gray['200']};
      }
      padding: ${theme.spacing['12']} ${theme.spacing['16']};
      background-color: ${theme.colors.white};
      width: 100%;
    }
  `}
`;

export const SelectItem = styled(Item)`
  ${({ theme }) => css`
    color: ${theme.colors.gray['900']};
    padding: ${theme.spacing['04']};
    cursor: pointer;

    &:hover {
      background-color: ${theme.colors.gray['100']};
      border-radius: ${theme.radius.sm};
    }
  `}
`;

export const SelectLabel = styled(ItemText)``;

export const ErrorMessage = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.error[700]};
    font-size: ${theme.typography.fontSize['14'].size};
    margin-top: ${theme.spacing['04']};
  `}
`;
