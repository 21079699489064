'use client';

import React, { FC, ReactNode, useState } from 'react';

import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import clsx from 'clsx';

import { Button } from '@/components';

import * as S from './styles';

export type DropDownMenuProps = {
  items: Array<{
    text: string;
    value: string | number;
    icon?: ReactNode;
    onClick?: (values?: string) => void;
    show?: boolean;
    className?: string;
  }>;
  text: string | ReactNode;
  subtext?: string;
  iconLeft?: boolean;
  account?: string;
  category:
    | 'danger'
    | 'multi-line'
    | 'default'
    | 'button'
    | 'account'
    | 'category'
    | 'divider'
    | 'icon';
  itemValue?: string;
};

const DropDownMenu: FC<DropDownMenuProps> = ({
  items,
  text,
  subtext,
  account,
  category = 'default',
  itemValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <DropdownMenu.Root open={isOpen} onOpenChange={setIsOpen} modal={false}>
      <S.DropdownTrigger asChild>
        <S.Container category={category}>
          {category === 'account' && account && (
            <>
              <S.AccountImage src={account} alt="Imagem da conta" />
              <S.TextContainer>
                <S.Text category={category}>{text}</S.Text>
                {subtext && (
                  <S.SubText category={category}>{subtext}</S.SubText>
                )}
              </S.TextContainer>
            </>
          )}

          {category === 'multi-line' && (
            <div>
              <S.Text category={category}>{text}</S.Text>
              <S.SubText category={category}>{subtext}</S.SubText>
            </div>
          )}

          {category === 'button' && (
            <Button size="md" color="primary">
              {text}
            </Button>
          )}

          {category === 'default' && (
            <S.Text category={category}>{text}</S.Text>
          )}

          {category === 'category' && (
            <S.Text category={category}>{text}</S.Text>
          )}

          {category === 'danger' && <S.Text category={category}>{text}</S.Text>}

          {category === 'divider' && <S.DividerLine />}

          {category === 'icon' && (
            <S.IconWrapper isOpen={isOpen}>{text}</S.IconWrapper>
          )}
        </S.Container>
      </S.DropdownTrigger>

      <DropdownMenu.Portal>
        <S.DropdownContent align={'end'} sideOffset={5}>
          {items.map((item, index) => (
            <React.Fragment key={index}>
              {index === items.length - 1 && <S.DividerLine />}

              <S.ListItem
                className={clsx(
                  item.className,
                  index === items.length - 1 && 'customLastItem'
                )}
                onClick={() => {
                  setIsOpen(false);
                  item?.onClick && item?.onClick(itemValue);
                }}>
                {item.icon && item.icon}
                <span>{item.text}</span>
              </S.ListItem>
            </React.Fragment>
          ))}
        </S.DropdownContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export { DropDownMenu };
